import React from 'react'
import styles from '@/styles/404.module.scss'
import Layout from '@/components/Layout'
export default function PageNotFound() {
  return (
    <Layout title={'404 Page Not Found'} description={'Page Not Found'} url={'https://www.tokkiro.com/404'} canonical={'https://www.tokkiro.com/404'} lang={'it'} image={'https://www.tokkiro.com/404.jpg'} keywords={['tokkiro', 'tokkiro news 2024', 'moda 2024', 'fitness 2024', 'salute 2024', 'benessere 2024', 'gaming 2024', 'tendenze moda 2024', 'consigli salute 2024', 'novità gaming 2024']}>
    <div className={styles.container}>

    </div>
    </Layout>
  )
}
